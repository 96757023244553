<template>
	<div style="background-color: #FFFFFF;height: 100vh;">
		<van-field
		  v-model="waterCode"
		  clearable
		  name="户号"
		  label="户号"
		  placeholder="户号"
		/>
		<van-field
		  v-model="userName"
		  clearable
		  name="户名"
		  label="户名"
		  placeholder="户名"
		/>
		<div style="margin: 16px;">
			<van-button block plain type="primary" @click="onSubmit" color="#0069FF">
				绑定用户
			</van-button>
		</div>
	</div>
</template>

<script>
	import request from "@/api/index.js";
	import { title } from "@/settings.js";
	export default {
		name: '',
		data() {
			return {
				title: title,
				isClick: true,
				waterCode: '',
				userName: '',
				openId: ''
			}
		},
		created() {
			this.openId = this.$route.query.openId
		},
		methods: {
			onSubmit() {
				if(this.waterCode == ''){
					this.$toast('请输入户号')
					return;
				}
				if(this.userName == ''){
					this.$toast('请输入户名')
					return;
				}
				if(this.openId == ''){
					this.$toast('用户openId为空')
					return;
				}
				let data={
				  waterCode: this.waterCode,
				  userName: this.userName,
				  openId: this.openId,
				}
				request.post('/wechat/publicAccount/bindOpenIdWithWaterCodeAndUserName', data).then(res=>{
					if(res.code == 200){
						this.$toast.success('绑定成功')
						this.$router.push({path: '/zsBindWaterCodeSuccess', query: {openId: this.openId}})
					}else{
						this.$toast.fail(res.msg)
					}
				})
			}
		}
	}
</script>

<style>
</style>